.mmmm{ 
    width: 100%;
}
.hidden-on-large-screens {
    display: none;
    margin-bottom: 10px;
  }

  .box-color{
    background: white;
    padding: 15px;
  }
  
  @media (max-width: 1024px) {
    .hidden-on-large-screens {
      display: flex; 
    }
  }