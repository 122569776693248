@tailwind base;
@tailwind components;
@tailwind utilities;

/* To hide scrolling in number inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body::-webkit-scrollbar-track {
  /* border-radius: 10px; */
  background: rgba(0, 0, 0, 0.1);
}
body::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  background: rgba(177, 0, 0, 0.9);
  transition: all;
  transition-duration: 200;
}
body::-webkit-scrollbar-thumb:hover {
  background: rgba(228, 0, 0, 0.9);
}
body::-webkit-scrollbar-thumb:active {
  background: rgba(228, 0, 0, 0.9);
}

.hideScrollBar::-webkit-scrollbar-track {
  /* border-radius: 10px; */
  background: transparent;
}
.hideScrollBar::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  background: transparent;
  transition: all;
  transition-duration: 200;
}
.hideScrollBar::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.hideScrollBar::-webkit-scrollbar-thumb:active {
  background: transparent;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("./assets/fonts/Satoshi-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  src: local("Noto Sans"),
    url("./assets/fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url("./assets/fonts/Inter.ttf") format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"), url("./assets/fonts/Nunito.ttf") format("truetype");
}

@font-face {
  font-family: "MaisonNeue";
  src: local("MaisonNeue"),
    url("./assets/fonts/MaisonNeue.ttf") format("truetype");
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.formSelectList {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  /* background-image: url("data:image/svg+xml,<svg width='18' height='10' viewBox='0 0 18 10' fill='black' xmlns='http://www.w3.org/2000/svg'><pathd='M9.00002 8.70834C8.42804 8.70834 7.85661 8.48782 7.42357 8.05479L0.903573 1.53479C0.808835 1.44005 0.808835 1.27663 0.903573 1.18189C0.998311 1.08716 1.16173 1.08716 1.25647 1.18189L7.77647 7.70189C8.45173 8.37715 9.54831 8.37716 10.2236 7.70189L16.7436 1.18189C16.8383 1.08716 17.0017 1.08716 17.0965 1.18189C17.1912 1.27663 17.1912 1.44005 17.0965 1.53479L17.45 1.88834L17.0965 1.53479L10.5765 8.05479C10.1434 8.48782 9.572 8.70834 9.00002 8.70834Z'fill='#A4A4A4' stroke='#A4A4A4' /></svg>"); */
  background-image: url("./assets/images/Icon\ rigth.svg");
  /* background-image: url('https://www.svgrepo.com/show/80156/down-arrow.svg?w=20&h=20'); */
  background-repeat: no-repeat;

  background-position-x: 95%;
  background-position-y: 50%;
  z-index: 10;
}

.smallIcon_select {
  background-size: 15px;

}

.selectList__greyBg {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #f2f2f2;
  width: 60px;
  /* translate: 20px; */
  border-radius: 10px;
  z-index: 1000;
  /* height: 100%  ; */
}

/* Spinner  */

.spin-animation {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
