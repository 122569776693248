
/* The radio-container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 24px;
  /* margin-bottom: 12px; */
  cursor: pointer;
  /* font-size: 22px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

.radio-container  * {

  transition: all 0.3s ease-in-out;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15.5px;
  width: 15.5px;
  /* background-color: #eee; */
  border-radius: 50%;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: #f7f7f7;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #fff;
  border: 2px solid #D80027;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {

    width: 0.3rem !important;
    height: 0.3rem !important;
    top: 3.5px;
    left: 0.25rem;
    border-radius: 100px;
    background: #D80027;
}