
/* The container */
.checkBoxContainer {
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: flex-start;
    align-items: center;
    /* gap: 20px; */
    position: relative;
    padding-left: 35px;
    /* margin-bottom: 12px; */
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    width: fit-content;
  }

  /* Hide the browser's default checkbox */
  .checkBoxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 5px;
    border: 2.43px solid #161616;
    border-radius: 3px;
    height: 19px;
    width: 19px;
    background-color: #fff;
  }

  /* On mouse-over, add a grey background color */
  .checkBoxContainer:hover input ~ .checkmark {
    background-color: #ffe6ea;
  }

  /* When the checkbox is checked, add a blue background */
  .checkBoxContainer input:checked ~ .checkmark {
    /* background-color: #2196F3; */
    color:   #161616;
    border: 2.43px solid #d80027;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkBoxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkBoxContainer .checkmark:after {
    left: 4.5px;
    top: 1.5px;
    width: 5px;
    height: 10px;
    border: solid #d80027;
    border-radius: 0.5px;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }