


.blog__imgWrap {
    display: inline-block; /* change the default display type to inline-block */
    overflow: hidden;      /* hide the overflow */
}


.blog__img:hover {
    transform:scale(1.2);
    -ms-transform:scale(1.2); /* IE 9 */
    -moz-transform:scale(1.2); /* Firefox */
    -webkit-transform:scale(1.2); /* Safari and Chrome */
    -o-transform:scale(1.2); /* Opera */
}