.fbShadow:hover {
  box-shadow: 0px 16.44443702697754px 27.407394409179688px 0 rgba(21,87,255,0.25);
}

.twShadow:hover {
  box-shadow:  0px 16.44443702697754px 27.407394409179688px 0 rgba(9,179,252,0.25);
}


.liShadow:hover {
  box-shadow:  0px 16.44443702697754px 27.407394409179688px 0 rgba(32,135,255,0.25);
}



.mailShadow:hover {
  box-shadow:  0px 16.44443702697754px 27.407394409179688px 0 rgba(216,0,39,0.25);
}



.waShadow:hover {
  box-shadow:  0px 16.44443702697754px 27.407394409179688px 0 rgba(34,139,34,0.25);
}
